.has-bg-color {
    z-index: auto !important;
}

// Success Icon Animation
@-webkit-keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #f8b273;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #f8b273;
}

#successAnimationResult {
  fill: #f8b273;
  opacity: 0;
}

#successAnimation.animated {
  -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
          animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationCheck {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationResult {
  -webkit-animation: 0.3s linear 0.9s both fadeIn;
          animation: 0.3s linear 0.9s both fadeIn;
}

// Blog Post Details
.flex-container {
  display: flex;
  flex-direction: row;
  margin-top: -32px;
}
.blog-post-content-left {
  marginLeft: 0px;
  float: left;
}
.icon-rotate {
  display: inline; 
  transform: rotate(180deg);
}
a.no-underline {
  text-decoration: none;
}
.blog-post-cta {
  max-width: 460px;
  padding-left: 40px;
  padding-top: 52px;
}
.ratgeber-cta {
  max-width: 460px;
  padding-left: 32px;
  padding-top: 0;
  padding-bottom: 32px;
}
.ratgeber-bg {
  background-color: get-color(light, 1);
}
.ratgeber-item-content {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    min-height: 275px;
}
@include media( '<=medium' ) {
  .flex-container {
    flex-direction: column;
  }
  .blog-post-cta {
    max-width: none;
    padding-left: 0;
    padding-top: 32px;
  }
  .ratgeber-cta {
    max-width: none;
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 32px;
  }
}