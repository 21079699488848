.pricing-slider {

    &.invert-color {

        .form-slider {
            color: get-color(light, 3);
        }
    }

    .form-slider {

        > span {
            font-weight: 500;
        }
    }
}

.pricing-item-header {
    @include divider(after);

	&::after {
		max-width: 88px;
	}
}

.pricing-item-price-currency {
	color: color(base);
}

.pricing-item-price-currency {
    font-weight: 500;
}

.pricing-item-features-title {
    font-weight: 700;
}

ul.pricing-item-features-list {
	@include divider(after);

	li {
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(before);

		&::after {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 12px;
			background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M5 11h14v2H5z" fill="' + get-color(dark, 3) + '" fill-rule="nonzero"/></svg>');
            background-repeat: no-repeat;
            order: -1;
		}

		&.is-checked {

			&::after {
				background-image: inline-svg('<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="none"><circle fill="' + get-color(alert, success) + '" cx="12" cy="12" r="12"/><path fill="' + get-color(light, 1) + '" d="M10.5 12.267l-2.5-1.6-1 1.066L10.5 16 17 9.067 16 8z"/></g></svg>');
			}
		}
	}
}

.chart-center-container{
	padding:20px;
	position:absolute;
	top:50px;
	left:80px;
	right:80px;
	bottom:100px;

	.their-share{
		font-size:16px;
		font-weight:bold;
		text-align:center;

		.share-value{
			width:50%;
			font-size: 20px;
			margin:auto;
		}

		.share-by{
			margin-top:10px;
		}

		.share-detail{
			font-weight:normal;
        	font-size:14px;
		}
	}
	
	.their-share:first-child{
		padding-bottom:10px;
		border-bottom:solid 1px #AAA;
	}
	
	@include media( '>small' ) {
		top:100px;
	}
}