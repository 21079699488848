.tiles-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	margin-right: -($tiles-items--padding__mobile / 2);
	margin-left: -($tiles-items--padding__mobile / 2);
	margin-top: -($tiles-items--padding__mobile / 2);

	&:last-of-type {
		margin-bottom: -($tiles-items--padding__mobile / 2);
	}

	&:not(:last-of-type) {
		margin-bottom: ($tiles-items--padding__mobile / 2);
	}

	&.push-left {

		&::after {
			content: '';
			flex-basis: $tiles-item--width;
			max-width: $tiles-item--width;
			box-sizing: content-box;
			padding-left: $tiles-items--padding__mobile / 2;
			padding-right: $tiles-items--padding__mobile / 2;
			height: 0;
		}
	}
}

.tiles-item {
	flex-basis: $tiles-item--width;
	max-width: $tiles-item--width;
	box-sizing: content-box;
	padding: $tiles-items--padding__mobile / 2;

	* {
		box-sizing: border-box;
	}
}

.tiles-item-inner {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	padding: $tiles-item--inner-padding-v $tiles-item--inner-padding-h;
}

@if ( $tiles-items--padding__desktop != null ) {

	@include media( '>medium' ) {

		.tiles-wrap {
			margin-right: -($tiles-items--padding__desktop / 2);
			margin-left: -($tiles-items--padding__desktop / 2);
			margin-top: -($tiles-items--padding__desktop / 2);

			&:last-of-type {
				margin-bottom: -($tiles-items--padding__desktop / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($tiles-items--padding__desktop / 2);
			}

			&.push-left {

				&::after {
					padding-left: $tiles-items--padding__desktop / 2;
					padding-right: $tiles-items--padding__desktop / 2;
				}
			}
		}

		.tiles-item {
			padding: $tiles-items--padding__desktop / 2;
		}
	}
}


/* Radio Button Tiles */
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height:  5rem;
    width:  7rem;
    margin: 0rem 1rem 1rem 0rem;

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid color-border(input);
      background-color: get-color(light, 2);
      padding: 1rem;
      transition: transform 300ms ease;
    }
    .radio-tile-label {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: get-color(dark, 1);
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: rgba(get-color(primary, 1), .08);
      border: 2px solid get-color(primary, 1);
      //color: white;
      transform: scale(1.1, 1.1);

      .icon svg {
        fill: white;
        background-color: transparent;
      }
      .radio-tile-label {
        //color: white;
        background-color: transparent;
      }
    }
  }
}
