.site-header {
	position: absolute !important;
	top: 0;
	width: 100%;
	z-index: 10 !important;
	background: color-bg(header);

	+ .site-content {

		.section:first-of-type {
			padding-top: $header-height__mobile;
		}
	}

	.brand {
		margin-right: $header-nav--padding-h;
	}	
}

.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
	height: $header-height__mobile;
}

.header-nav {
	flex-grow: 1;

	.header-nav-inner {
		display: flex;
		flex-grow: 1;
	}

    ul {
        display: flex;
        align-items: center;
		flex-grow: 1;
        white-space: nowrap;
		margin-bottom: 0;

		&:first-of-type {
			flex-wrap: wrap;
		}
    }

    li {

		+ .header-button {
			margin-left: $header-nav--padding-h;
		}

		+ .have-dropdown {
			position:relative;

			i.arrow{
				border: solid #484349;
				border-width: 0 3px 3px 0;
				display:inline-block;
				padding:3px;
				margin-bottom:3px;
			}

			a:hover i.arrow{
				border-color:#f8b273;
			}

			i.arrow.down{
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}


			ul.dropdown-list{
				display:none;
				list-style-type: none;
				background-color: get-color(light, 1);
				--tw-shadow:0 10px 15px -3px rgba(0,0,0,0.04),0 4px 6px -2px rgba(0,0,0,0.02);
				box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
				position:absolute;
				top:16px;
				left:0px;
				right:0px;
				padding-top:16px;
				padding-bottom:8px;
				padding-left:0px;
				li{
					display: block;
					padding:8px;
				}
			}

			ul.dropdown-list.dropdown-hide{
				display:none !important;
			}

			
		}

		+ :hover ul.dropdown-list{
			display:block !important;
		}

    }


    a:not(.button) {
		display: block;
        @include anchor-aspect(header);
		@include font-weight(header-link);
		text-transform: $link-header--transform;
        padding: 0 $header-nav--padding-h;

		.invert-color & {
			@include anchor-aspect(header, inverse);
		}
    }

	a.button {
		margin-left: $header-nav--padding-h;
	}
}

.header-nav-center:first-of-type {
	flex-grow: 1;
	justify-content: flex-end;
}

.header-nav-right {
	justify-content: flex-end;

	+ .header-nav-right {
		flex-grow: 0;
	}
}

.header-nav-toggle {
	display: none;
}

@include media( '<=medium' ) {

	.header-nav-toggle {
		display: block;

		// Header navigation when the hamburger is a previous sibling
		+ .header-nav {
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9999;
			background: color-bg(menu-mobile);
			max-height: 0;
	        opacity: 0;
	        overflow: hidden;
	        transition: max-height .25s ease-in-out, opacity .15s;

			&.is-active {
				opacity: 1;
			}

			.header-nav-inner {
				flex-direction: column;
				padding: $header-nav--padding-v__mobile;
			}

			ul {
				display: block;
				text-align: center;


				li.have-dropdown{
					border-top:solid 1px white;
					
					i.arrow{
						display:none;
					}

					ul.dropdown-list{
						position:relative;
						display:block;
						background: transparent;
						margin:0px;
						padding:0px;
						top:0px;
						left:35%;
						text-align:left;
						li{
							padding:0px;
						}
					}
				}

			    a:not(.button) {
					display: inline-flex;
			        @include anchor-aspect(header-mobile);
					padding-top: $header-nav--padding-v__mobile / 2;
					padding-bottom: $header-nav--padding-v__mobile / 2;
			    }
			}

			a.button {
				margin-left: 0;
				margin-top: $header-nav--padding-v__mobile / 2;
				margin-bottom: $header-nav--padding-v__mobile / 2;
			}
		}
	}
}

@include media( '>medium' ) {

	.site-header {

		+ .site-content {

			.section:first-of-type {
				padding-top: $header-height__desktop;
			}
		}
	}

	.site-header-inner {
		height: $header-height__desktop;
	}
}
